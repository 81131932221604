@import "driver.js/dist/driver.min.css";

@import "./themes/highcontrast/highcontrast.css";

@import "./themes/dark/midnight.css";
@import "./themes/dark/monokai.css";
@import "./themes/dark/dark.css";
@import "./themes/dark/twilight.css";

@import "./themes/light/light.css";
@import "./themes/light/aquamarine.css";
@import "./themes/light/champagne.css";

div#driver-highlighted-element-stage {
  opacity: 0.5;
}

div#driver-popover-item {
  background-color: var(--secondary-background);
  color: var(--text-color);

  border: 2px solid var(--border-color);

  text-align: center;
}

div#driver-popover-item .driver-popover-footer button {
  padding: 8px 16px 8px 16px;

  font-size: 14px;

  background-color: transparent;

  border: none;

  padding: 10px;

  color: var(--text-color);

  border: 2px solid var(--border-color);

  text-shadow: none;
}

div#driver-popover-item .driver-popover-tip.top {
  border-color: transparent transparent var(--border-color);
}

.driver-close-btn {
  margin-right: 10px;
}

div#driver-popover-item .driver-popover-footer button.driver-disabled {
  color: var(--line-color);
}

div#driver-popover-item .driver-popover-description {
  color: var(--text-color);
}

* {
  font-family: monospace;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

html, body {
  width: 100%;
  height: 100%;

  overflow: hidden;
}

svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  pointer-events: none;
  z-index: 100;
}

#mainContainer {
  width: 100%;
  height: 100%;

  background-color: var(--main-container-background);
}

#bottomContainer:before {
  content: '';

  width: 100%;
  height: 10px;

  cursor: n-resize;

  display: block;

  z-index: 10;
}

#bottomContainer {
  top: -10px;
  position: relative;

  height: calc(30% + 10px);

  display: flex;
  flex-direction: column;

  overflow: auto;

  z-index: 5;
}

#interpretContainer, #replContainer, #issuesContainer, #aboutContainer {
  flex-grow: 100;

  flex-direction: column;

  display: none;

  overflow: auto;
}

#interpretContainer.activeContainer, #replContainer.activeContainer, #issuesContainer.activeContainer, #aboutContainer.activeContainer {
  display: flex;
  background-color: var(--secondary-background);
}

#aboutContainer {
  color: var(--text-color);

  padding: 10px;

  font-size: 14px;

  align-items: center;
  justify-content: space-around;

  text-align: center;

  flex-direction: row;

  overflow: hidden;
}

#aboutTriple {
  justify-content: flex-start;
  align-items: center;

  flex-direction: column;

  display: flex;
}

#changelog {
  overflow: auto;

  height: 100%;
}

ul {
  text-align: left;
}

#aboutTriple h2, #aboutTriple h3 {
  margin-bottom: 0;
}
#aboutTriple > div {
  padding: 10px;
}

#interpretTopRow, #bottomTopRow, #issuesTopRow {
  width: 100%;

  color: var(--text-color);

  font-size: 14px;

  background-color: var(--main-container-background);
}

#interpretTopRow {
  padding: 4px;
}

#interpretTopRow #tourButton {
  margin-right: 0;
}

#clearButton, #tourButton {
  border: 2px solid var(--border-color);
}

#tourButton {
  border-color: var(--accent-color);
  background-color: rgba(var(--interactable-action-background), 0.05);
}

#tourButton:hover {
  background-color: rgba(var(--interactable-action-background), 0.15);
}

#tourButton:active {
  background-color: rgba(var(--interactable-action-background), 0.25);
}

button.activeTab {
  background-color: rgba(var(--interactable-action-background), 0.15);
}

#issuesWrapper {
  flex-grow: 100;
  color: var(--text-color);

  justify-content: center;
  align-items: center;

  flex-direction: column;

  text-align: center;

  display: flex;
}

#issuesWrapper > :first-child {
  font-size: 2vw;
}

#bottomTopRow > :last-child {
  float: right;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  position: relative;
  right: 1px;
}

#interpretTopRow > :last-child {
  float: right;
  height: calc(100% + 10px);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  position: relative;
  top: -4px;
}

#interpretTopRow > :last-child > :not(:last-child) {
  margin-right: 25px;
}

#clearOnRunContainer > span {
  vertical-align: top;
  position: relative;
  top: 6px;
}

input[type="checkbox"] {
  height: 22px;
  width: 22px;

  display: inline-block;

  margin-right: 3px;
}

/*#runningInfo::after {
  width: 2px;
  height: calc(100% + 5px);

  margin: 0 10px 0 15px;

  content: "";
  display: inline-block;

  vertical-align: top;

  position: relative;
  top: -5px;

  background-color: #666;
}*/

#interpretTopRow button {
  margin-right: 10px;
  vertical-align: top;

  padding: 8px 16px 8px 16px;
}

button {
  font-size: 14px;

  background-color: transparent;

  border: none;

  padding: 10px;

  color: var(--text-color);

  cursor: pointer;
  
  user-select: none;
}

button:hover, #bottomTopRow button.activeTab:hover {
  background-color: rgba(var(--interactable-action-background), 0.1);
}

button:active, #bottomTopRow button.activeTab:active {
  background-color: rgba(var(--interactable-action-background), 0.2);
}

button:focus {
  outline: none;
}

select {
  background-color: transparent;
  color: var(--text-color);

  padding: 1px;

  height: 35px;
  width: 110px;

  display: inline-block;

  border: 2px solid var(--border-color);
}

option {
  background-color: var(--code-background);
}

option[disabled="true"] {
  font-weight: bold;
  color: var(--accent-color);
}

select:hover {
  background-color: rgba(var(--interactable-action-background), 0.1);
}

select:hover {
  background-color: rgba(var(--interactable-action-background), 0.2);
}

#executeButton {
  background-color: #187C28;
  border: 2px solid #004907;

  color: #fff;
}

#executeButton.hide {
  display: none;
}

#executeButton:hover {
  background-color: #1d9a31;
}

#executeButton:active {
  background-color: #23b83b;
}

#haltButton {
  background-color: transparent;
  border: 2px solid #CC2121;

  display: none;
}

#haltButton.show {
  display: inline-block;
}

#haltButton:hover {
  background-color: rgba(255, 0, 0, 0.2);
}

#haltButton:active {
  background-color: rgba(255, 0, 0, 0.4);
}

#replOutput, #runOutput {
  flex-grow: 100;

  font-size: 14px;
  line-height: 16px;

  color: var(--text-color);

  white-space: pre;

  padding: 4px;

  overflow: auto;
}

#codeContainer {
  width: 100%;
  height: 70%;

  background-color: var(--code-background);

  display: flex;

  align-items: flex-start;
  align-content: flex-start;

  flex-direction: column;
}

#codeContainerMain {
  display: flex;

  align-items: flex-start;
  align-content: flex-start;

  flex: 1;

  height: 100%;
  width: 100%;
}

#codeLines {
  float: left;

  height: 100%;
  width: fit-content;
  padding-left: 4px;

  background-color: var(--secondary-background);

  text-align: right;
  float: left;

  border-right: 2px solid var(--border-color);

  font-family: monospace;
  white-space: pre;

  font-size: 24px;

  padding-right: 5px;

  color: var(--line-color);

  overflow: hidden;
}

#codeLines, #editor {
  font-size: 14px;
  line-height: 16px;
  padding-top: 2px;
}

pre {
  z-index: 3;
  background-color: var(--code-background);
}

#editor {
  height: 100%;
  width: 100%;

  padding-left: 4px;

  display: inline-block;

  caret-color: var(--text-color);

  overflow: auto;

  z-index: 2;
}

:focus {
  outline: var(--text-color) solid 1px;
}

#editor * {
  z-index: 2;
}

pre {
  margin: 0;
  /*margin-left: 50px;*/

  vertical-align: top;

  height: 100%;
  /*width: calc(100% - 50px);*/

  color: var(--text-color);
  
  flex-grow: 100;
}

.command-2, .command-5, .command-6 {
  color: var(--syntax-command);
}

.lone-command-6::after {
  content: "(" attr(six-number) ")";
  color: var(--syntax-jump-bracket);
  margin-left: 3px;
}

.variable-name {
  color: var(--syntax-variable-name);
}

.command-other {
  color: var(--command-other);
}

.variable-value {
  color: var(--syntax-variable-value);
}

.jump-number {
  color: var(--syntax-jump-number);
}

.semicolon {
  color: var(--syntax-semicolon); /*#C3E991*/
}
