body[data-theme="light"] {
  --code-background: #fff;
  --main-container-background: #ddd;
  --secondary-background: #ccc;

  --border-color: #555;

  --text-color: #111;

  --line-color: var(--text-color);

  --interactable-action-background: 0, 0, 0;
  
  --accent-color: #5c98cd;
}