body[data-theme="midnight"] {
  --code-background: #111;
  --main-container-background: #1f1f1f;
  --secondary-background: #171717;

  --border-color: #454545;

  --text-color: rgb(255, 255, 255);

  --line-color: rgb(228, 187, 53);

  --interactable-action-background: 255, 255, 255;

  --accent-color: #5c98cd;
}