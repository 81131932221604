body[data-theme="dark"] {
  --code-background: #444;
  --main-container-background: #555;
  --secondary-background: #666;

  --border-color: #222;

  --text-color: #fff;

  --line-color: rgb(228, 187, 53);

  --interactable-action-background: 255, 255, 255;

  --accent-color: #5c98cd;
}